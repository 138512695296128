<template>
  <div
    ref="bodyPanel"
    class="rk-body__panel animate-menu"
    :class="{ open: menuStatus, render: !menuStatus }"
  >
    <div class="responsive-menu">
      <div class="rk-body__panel-btn">
        <a
          class="rk-link rk-link--default d-inline-block"
          @click.prevent="toggleMenu"
        >
          <img
            v-if="!menuStatus"
            :src="logoHovered"
            class="rk-body__panel-btn-svg d-block d-lg-none"
            alt="Open Menu"
            draggable="false"
          />
          <img
            v-if="!menuStatus"
            :src="logoState"
            class="rk-body__panel-btn-svg d-none d-lg-block"
            alt="Open Menu"
            draggable="false"
            @mouseenter="logoState = logoHovered"
            @mouseout="logoState = logoUnhovered"
          />
          <img
            v-if="menuStatus"
            src="/images/rezkit-glyph-close-v2.svg"
            class="rk-body__panel-btn-svg"
            alt="Close Menu"
            draggable="false"
          />
        </a>
        <h4 class="panel-title ms-3 mb-0 d-lg-none">Rezkit</h4>
      </div>
      <div class="rk-menu">
        <div class="rk-menu__content">
          <ul class="rk-list rk-list--no-type border-bottom">
            <li class="ps-2 mb-3" @click="toggleMobileMenu">
              <nuxt-link
                class="rk-link rk-link--default d-flex align-items-center"
                to="/"
              >
                <i
                  class="rk-icon rk-icon--text-df rk-icon fontello icon-sys-dashboard me-2"
                ></i>
                <div class="rk-text rk-text--default rk-semi-bold">
                  Dashboard
                </div>
              </nuxt-link>
            </li>
            <li class="ps-2 mb-3" @click="toggleMobileMenu">
              <nuxt-link
                class="rk-link rk-link--default d-flex align-items-center"
                to="/customers"
              >
                <i
                  class="rk-icon rk-icon--text-df rk-icon fontello icon-user-list me-2"
                ></i>
                <div class="rk-text rk-text--default rk-semi-bold">
                  Customers
                </div>
              </nuxt-link>
            </li>
            <li class="ps-2 mb-3" @click="toggleMobileMenu">
              <nuxt-link
                class="rk-link rk-link--default d-flex align-items-center"
                to="/bookings"
              >
                <i
                  class="rk-icon rk-icon--text-df rk-icon fontello icon-calendar me-2"
                ></i>
                <div class="rk-text rk-text--default rk-semi-bold">
                  Bookings
                </div>
              </nuxt-link>
            </li>
            <li class="ps-2 mb-3" @click="toggleMobileMenu">
              <nuxt-link
                class="rk-link rk-link--default d-flex align-items-center"
                to="/products"
              >
                <i
                  class="rk-icon rk-icon--text-df fontello icon-sys-product-list me-2"
                />
                <div class="rk-text rk-text--default rk-semi-bold">
                  Products
                </div>
              </nuxt-link>
            </li>
            <li class="ps-2 mb-3" @click="toggleMobileMenu">
              <nuxt-link
                class="rk-link rk-link--default d-flex align-items-center"
                to="/discounts"
              >
                <i
                  class="rk-icon rk-icon--text-df fontello icon-discount me-2"
                />
                <div class="rk-text rk-text--default rk-semi-bold">
                  Discounts
                </div>
              </nuxt-link>
            </li>
          </ul>
          <ul class="rk-list rk-list--no-type pt-3 border-bottom">
            <li class="ps-2 mb-3" @click="toggleMobileMenu">
              <nuxt-link
                class="rk-link rk-link--default d-flex align-items-center"
                to="/customers/create"
              >
                <i
                  class="rk-icon rk-icon--text-sm rk-icon fontello icon-sys-add-customer me-2"
                ></i>
                <div class="rk-text rk-text--default rk-semi-bold">
                  Create Customer
                </div>
              </nuxt-link>
            </li>
            <li class="ps-2 mb-3" @click="toggleMobileMenu">
              <nuxt-link
                class="rk-link rk-link--default d-flex align-items-center"
                to="/bookings/create"
              >
                <i
                  class="rk-icon rk-icon--text-df rk-icon fontello icon-sys-add-booking me-1"
                ></i>
                <div class="rk-text rk-text--default rk-semi-bold">
                  Create Booking
                </div>
              </nuxt-link>
            </li>
            <li
              v-if="canCreateProduct"
              class="ps-2 mb-3"
              @click="toggleMobileMenu"
            >
              <nuxt-link
                class="rk-link rk-link--default d-flex align-items-center"
                to="/products/create"
              >
                <i
                  class="rk-icon rk-icon--text-df rk-icon fontello icon-sys-add-product me-1"
                ></i>
                <div class="rk-text rk-text--default rk-semi-bold">
                  Create Product
                </div>
              </nuxt-link>
            </li>
            <li class="ps-2 mb-3" @click="toggleMobileMenu">
              <nuxt-link
                class="rk-link rk-link--default d-flex align-items-center"
                to="/discounts/create"
              >
                <i
                  class="rk-icon rk-icon--text-df rk-icon fontello icon-add-discount me-1"
                ></i>
                <div class="rk-text rk-text--default rk-semi-bold">
                  Create Discount
                </div>
              </nuxt-link>
            </li>
          </ul>
          <ul class="rk-list rk-list--no-type pt-3">
            <li class="ps-2 mb-3" @click="toggleMobileMenu">
              <nuxt-link
                class="rk-link rk-link--default d-flex align-items-center"
                to="/settings"
              >
                <i
                  class="rk-icon rk-icon--text-df rk-icon fontello icon-cog me-2"
                ></i>
                <div class="rk-text rk-text--default rk-semi-bold">
                  Settings
                </div>
              </nuxt-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
export default defineComponent({
  computed: {
    menuStatus() {
      return useGlobalStore().menu
    },
    orgId() {
      return useAuthStore().user ? useAuthStore().user!.organization.id : ''
    },
  },
  data() {
    const logoState: string = '/images/rezkit-glyph-colour-v2.svg'
    const logoUnhovered: string = '/images/rezkit-glyph-colour-v2.svg'
    const logoHovered: string = '/images/rezkit-glyph-hamburger-v2.svg'
    const canCreateProduct: boolean = false

    return {
      canCreateProduct,
      logoHovered,
      logoUnhovered,
      logoState,
    }
  },
  methods: {
    toggleMenu() {
      useGlobalStore().toggleMenu()
    },
    toggleMobileMenu() {
      const winWidth = window.innerWidth
      if (winWidth <= 992) {
        this.toggleMenu()
      }
    },
  },
  mounted() {
    this.canCreateProduct =
      this.$growthbook?.isOn('sales:enable_product_create') || false
  },
  watch: {
    orgId: {
      handler() {},
    },
  },
})
</script>

<style lang="scss"></style>
